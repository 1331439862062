import request from '@/utils/http'
import { awaitWrap } from '@/utils/util'

/**
 * 获取商品一级分类列表(page)
 * @param {*} "pageNo" 页码
 * @param {*} "pageSize" 页数
 * @param {*} "keyWords" 名称
 * @returns
 */
export const categoryPage = ({ ...params }) => {
  return awaitWrap(
    request({
      url: `/goods/category/first_page`,
      method: 'get',
      params,
      isLoading: true
    })
  )
}

/**
 * 获取商品一级分类列表(list)
 * @param {*} "keyWords" 名称
 * @returns
 */
export const categoryList = () => {
  return awaitWrap(
    request({
      url: `/goods/category/first_list`,
      method: 'get',
      isLoading: true
    })
  )
}

/**
 * 获取商品二级分类列表
 * @param {*} "categoryId" 
 * @returns
 */
export const categorySecondList = ({ ...params }) => {
  return awaitWrap(
    request({
      url: `/goods/category/second_list`,
      method: 'get',
      params: params,
      isLoading: true
    })
  )
}

/**
 * 添加/编辑商品分类和商品分类状态
 * @returns
 */
export const categoryUpdate = ({ ...data }) => {
  return awaitWrap(
    request({
      url: `/goods/category/update`,
      method: 'post',
      data,
      isLoading: true
    })
  )
}

/**
 * 商品列表
 * @param {*} "pageNo" 页码
 * @param {*} "pageSize" 页数
 * @param {*} "type" 
 * @param {*} "name" 名称
 * @returns
 */
export const goodsList = ({ ...params }) => {
  return awaitWrap(
    request({
      url: `/goods/page`,
      method: 'get',
      params: params,
      isLoading: true
    })
  )
}

/**
 * 保存or新增商品信息
 * @returns
 */
export const goodsUpdate = ({ ...data }) => {
  return awaitWrap(
    request({
      url: `/goods/update`,
      method: 'post',
      data,
      isLoading: true
    })
  )
}

/**
 * 更新商品状态
 * @param {*} "goodsId"
 * @param {*} "status"
 * @returns
 */
export const goodsStatusUpdate = ({ ...data }) => {
  return awaitWrap(
    request({
      url: `/goods/status/update`,
      method: 'post',
      data,
      isLoading: true
    })
  )
}

/**
 * 获取商品详情
 * @param {*} "goodsId"
 * @returns
 */
export const goodsGet = ({ ...params }) => {
  return awaitWrap(
    request({
      url: `/goods/detail/${params.goodsId}`,
      method: 'get',
      isLoading: true
    })
  )
}



//无效
/**
 * 更新库存
 * @param {*} "goodsId"
 * @param {*} "number"
 * @param {*} "type"
 * @returns
 */
export const goodsStockUpdate = ({ ...data }) => {
  return awaitWrap(
    request({
      url: `/goods/stock/update`,
      method: 'post',
      data,
      isLoading: true
    })
  )
}

/**
 * E店宝商品列表
 * @param {*} "pageNo" 页码
 * @param {*} "pageSize" 页数
 * @param {*} "name" 名称
 * @returns
 */
export const edbGoodsList = ({ ...params }) => {
  let { pageNo, pageSize, ...param } = params
  return awaitWrap(
    request({
      url: `/goods/edb/list/${pageNo}/${pageSize}`,
      method: 'get',
      params: param,
      isLoading: false
    })
  )
}

/**
 * 解绑E店宝商品
 * @param {*} "edbId"
 * @returns
 */
export const edbGoodsUnbind = ({ ...data }) => {
  return awaitWrap(
    request({
      url: `/goods/edb/unbind`,
      method: 'post',
      data,
      isLoading: false
    })
  )
}
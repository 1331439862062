<!--  -->
<template>
  <el-card>
    <el-form ref="form" :rules="rules" :model="form" label-position="left">
      <div class="defalut_attr">
        <el-form-item class="sku_list_group">
          <span style="margin:0 10px">添加规格:</span>
          <!-- 自定义添加规格 -->
          <el-popover placement="top" width="240" v-model="add_popover_bool" @after-enter="$refs.addValueInput.focus()">
            <div style="display: flex; grid-gap: 10px;">
              <el-input ref="addValueInput" v-model.trim="add_value" @keyup.enter.native="confirm()" />
              <el-button size="mini" type="primary" @click="confirm()">确定</el-button>
            </div>
            <el-button slot="reference" size="small" type="danger" style="margin-right: 10px;">自定义</el-button>
          </el-popover>
          <!-- 默认 -->
          <el-button :disabled="hasSkuArr(item, idx)" size="small" @click="clickDefaultAttr(item)" v-for="(item, idx) in default_attr" :key="idx">{{ item }}</el-button>
        </el-form-item>
        <el-form-item>
          <!-- 展示已经选择的 -->
          <div v-for="(item, index) in form.sku_arr" :key="index" class="sku_list_group">
            <div class="sku_list_warp">
              <!-- 属性 -->
              <span style="margin:0 10px">规格:</span>
              <el-input v-model.trim="item.attr" @focus="attrFocus(item.attr)" @blur="attrBlur(item.attr)" placeholder="属性" size="small" style="width: 120px;margin-right: 10px;">
                <i slot="suffix" @click="deleteAttr(index)" class="el-input__icon el-icon-error "></i>
              </el-input>
              <span style="margin:0 10px">规格值:</span>
              <!-- 属性值 -->
              <div v-for="(ktem, kndex) in item.valueList" :key="kndex" style="margin-right: 20px;">
                <el-input
                  size="small"
                  ref="attrValueInput"
                  v-model.trim="item.valueList[kndex]"
                  placeholder="值"
                  style="width: 100px;"
                  @focus="attrValueFocus(item.valueList[kndex])"
                  @keyup.enter.native="newAttrValueBlur(item.attr, item.valueList[kndex])"
                  @blur="newAttrValueBlur(item.attr, item.valueList[kndex])"
                >
                  <i slot="suffix" v-if="item.valueList.length > 1" @click="deleteSmall(index, kndex, item.attr, item.valueList[kndex])" class="el-input__icon el-icon-error"></i>
                </el-input>
              </div>
              <el-button :disabled="item.valueList.includes('')" @click="addAttributeValue(index)" type="primary" size="mini" icon="el-icon-plus">添加值</el-button>
            </div>
          </div>
        </el-form-item>
        <div class="defalut_param_wrap">
          <div class="label">默认参数：</div>
          <el-form-item prop="" class="defalut_param_group">
            <el-input
              :disabled="!form.sku_arr.length"
              size="small"
              v-model="defaultParams.零售价"
              @blur="handleInputDefaultParams"
              @keyup.enter.native="handleInputDefaultParams"
              class="defalut_input"
              placeholder="输入价格"
            >
              <template slot="prepend">零售价</template>
            </el-input>
          </el-form-item>
          <el-form-item prop="" class="defalut_param_group">
            <el-input
              :disabled="!form.sku_arr.length"
              size="small"
              v-model="defaultParams.销售价"
              @blur="handleInputDefaultParams"
              @keyup.enter.native="handleInputDefaultParams"
              class="defalut_input"
              placeholder="输入价格"
            >
              <template slot="prepend">销售价</template>
            </el-input>
          </el-form-item>
          <el-form-item prop="" class="defalut_param_group">
            <el-input
              :disabled="!form.sku_arr.length"
              size="small"
              v-model="defaultParams.成本价"
              @blur="handleInputDefaultParams"
              @keyup.enter.native="handleInputDefaultParams"
              class="defalut_input"
              placeholder="输入价格"
            >
              <template slot="prepend">成本价</template>
            </el-input>
          </el-form-item>
          <el-form-item prop="" class="defalut_param_group">
            <el-input
              :disabled="!form.sku_arr.length"
              size="small"
              v-model="defaultParams.库存"
              @blur="handleInputDefaultParams"
              @keyup.enter.native="handleInputDefaultParams"
              class="defalut_input"
              placeholder="输入数量"
            >
              <template slot="prepend">库存</template>
            </el-input>
          </el-form-item>
        </div>
      </div>
      <div class="sku_table_wrap">
        <div class="label">
          <span class="title">规格列表</span>
          <span class="refreshTable" @click="manualGenerateTable">刷新表格</span>
        </div>
        <!-- 生成的表 -->
        <el-table :data="form.table_data" :span-method="spanMethod" header-cell-class-name="header_cell_class" border>
          <template v-for="item_column in table_column">
            <el-table-column class="image_column" v-if="item_column == '图片'" :key="item_column" min-width="150" width="170" align="center" :resizable="false" label="图片">
              <template slot="header">
                <div><span style="color: #ff5353;">*</span>图片</div>
              </template>
              <template slot-scope="{ row, $index }">
                <el-form-item class="image_box" :prop="`table_data.${$index}.图片`" :rules="rules.sku_img" label-width="0" label=" ">
                  <UploadPlace
                    v-model="row[item_column]"
                    @changeImage="changeImage($event, row, $index, item_column)"
                    :styleObject="{ width: '50px', height: '50px' }"
                    borderRadius="0"
                  />
                </el-form-item>
              </template>
            </el-table-column>
            <!-- 零售价 -->
            <el-table-column v-else-if="item_column == '零售价'" :key="item_column" align="center" :resizable="false" :label="item_column">
              <template slot="header">
                <div><span style="color: #ff5353;">*</span>零售价</div>
              </template>
              <template slot-scope="{ row, $index }">
                <el-form-item class="table_form_item" :prop="`table_data.${$index}.零售价`" :rules="rules.sku_sale_price" label-width="0" label=" ">
                  <el-input v-model="row[item_column]" :placeholder="item_column" />
                </el-form-item>
              </template>
            </el-table-column>
            <!-- 销售价 -->
            <el-table-column v-else-if="item_column == '销售价'" :key="item_column" align="center" :resizable="false" :label="item_column">
              <template slot="header">
                <div><span style="color: #ff5353;">*</span>销售价</div>
              </template>
              <template slot-scope="{ row, $index }">
                <el-form-item class="table_form_item" :prop="`table_data.${$index}.销售价`" :rules="rules.sku_sale_price" label-width="0" label=" ">
                  <el-input v-model="row[item_column]" :placeholder="item_column" />
                </el-form-item>
              </template>
            </el-table-column>
            <!-- 成本价 使用表单验证 和 自定义表头 -->
            <el-table-column v-else-if="item_column == '成本价'" :key="item_column" align="center" :resizable="false">
              <!-- 自定义表头 -->
              <template slot="header">
                <div><span style="color: #ff5353;">*</span>成本价</div>
              </template>
              <template slot-scope="{ row, $index }">
                <el-form-item class="table_form_item" :prop="`table_data.${$index}.成本价`" :rules="rules.sku_sale_price" label-width="0" label=" ">
                  <el-input v-model="row[item_column]" :placeholder="item_column" />
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column v-else-if="item_column == '规格编码'" :key="item_column" align="center" :resizable="false">
              <!-- 自定义表头 -->
              <template slot="header">
                <div><span style="color: #ff5353;">*</span>规格编码</div>
              </template>
              <template slot-scope="{ row, $index }">
                <el-form-item class="table_form_item" :prop="`table_data.${$index}.规格编码`" :rules="rules.sku_sale_price" label-width="0" label=" ">
                  <el-input v-model="row[item_column]" :placeholder="item_column" />
                </el-form-item>
              </template>
            </el-table-column>
            <!-- 库存 -->
            <el-table-column v-else-if="item_column == '库存'" :key="item_column" align="center" :resizable="false" :label="item_column">
              <template slot="header">
                <div><span style="color: #ff5353;">*</span>库存</div>
              </template>
              <template slot-scope="{ row, $index }">
                <el-form-item class="table_form_item" :prop="`table_data.${$index}.库存`" :rules="rules.sku_sale_price" label-width="0" label=" ">
                  <el-input v-model="row[item_column]" :placeholder="item_column" />
                </el-form-item>
              </template>
            </el-table-column>
            <!-- 规格图片 -->
            <el-table-column v-else-if="item_column == '规格图片'" :key="item_column" align="center" :resizable="false" :label="item_column">
              <template slot="header">
                <div><span style="color: #ff5353;">*</span>规格图片</div>
              </template>
              <template slot-scope="{ row, $index }">
                <el-form-item class="image_box" :prop="`table_data.${$index}.规格图片`" :rules="rules.sku_img" label-width="0" label=" ">
                  <UploadPlace v-model="row[item_column]" :styleObject="{ width: '50px', height: '50px' }" borderRadius="0" />
                </el-form-item>
              </template>
            </el-table-column>

            <!-- 其他属性列 -->
            <el-table-column v-else align="center" :resizable="false" :key="item_column" :prop="item_column" :label="item_column" />
          </template>
        </el-table>
      </div>
    </el-form>
    <!-- <el-button type="primary" @click="onSubmit()" style="margin-left: 100px;">提交</el-button> -->
  </el-card>
</template>

<script>
import { errScroll, generateCode, isEmpty } from '@/utils/util'
import UploadPlace from '../upload/uploadPlace.vue'
import { reject, resolve } from 'tinymce'
import { specPage } from '@/api/SpecController'

// ['零售价', '销售价', '成本价', '规格编码', '库存', '规格图片']
let base_column = [] // 基本的列
let first_column_rule = [] // 第一列与第二列使用相同的合并规则 (不能存在data中)
let old_attr = '' // 每次当属性获得焦点时都会获取输入框内的值，保存于此
let old_attr_value = '' // 每次当属性值获得焦点时都会获取输入框内的值，保存于此
let paramName = {
  retailPrice: '零售价',
  salesPrice: '销售价',
  costPrice: '成本价',
  skuCode: '规格编码',
  stock: '库存',
  image: '规格图片'
}
let _paramName = {}
let defalut_param = {}
let defalut_param_arr = []
for (const [key, value] of Object.entries(paramName)) {
  _paramName[value] = key
  base_column.push(value)
  defalut_param[value] = ''
  defalut_param_arr.push({
    label: `${value}`,
    value: ''
  })
}

export default {
  components: { UploadPlace },
  props: {
    productCode: {
      type: String,
      default: () => {
        return ''
      }
    },
    smallImg: String,
    specTemplate: String,
    skuList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      goodsId: this.$route.query.goodsId,
      // 录入sku相关
      default_attr: [], // 默认规格 ['颜色', '尺寸', '重量']
      table_column: base_column, // 表格列
      add_popover_bool: false, // 添加属性的小弹窗
      add_value: '', // 添加属性的
      attr_name_value: {},
      cur_attr_name: '', // 当前的attr_name
      defaultParams: {},
      // 表单
      form: {
        sku_arr: [],
        table_data: [] // 表格中的数据
      },
      _skuList: [],
      // 验证规则
      rules: {
        // sku 相关验证
        sku_arr: {
          validator: (rule, value, callback) => {
            if (value.length === 0) return callback(new Error('请添加规格'))
            else return callback()
          },
          trigger: 'blur'
        },
        sku_img: [{ required: true, message: '图片不能为空', trigger: 'blur' }],
        sku_sale_price: { required: true, message: '不能为空', trigger: 'blur' }
      }
    }
  },
  computed: {
    // 已添加的属性(字符串数组)
    selectedAttr() {
      return this.form.sku_arr.map(x => x.attr)
    },
    // 已经存在sku列表中
    hasSkuArr() {
      return (attr, idx) => {
        if (!this.form.sku_arr.length) return
        let findIndex = this.form.sku_arr.findIndex(item => item.attr == attr)
        return findIndex == idx
      }
    }
  },
  watch: {
    specTemplate(v) {
      let obj = JSON.parse(v)
      for (const [key, value] of Object.entries(obj)) {
        this.form.sku_arr.push({
          attr: key,
          valueList: value
        })
      }
    },
    skuList(arr) {
      for (const [key, value] of Object.entries(paramName)) {
        arr.map(item => {
          if (item[key]) {
            item[`${value}`] = item[key]
            item['图片'] = item['image']
          }
        })
      }
      this._skuList = arr
      this.manualGenerateTable()
    },
    smallImg(v) {
      console.log(v)
      console.log(this.form.table_data)
      if (!this.form.table_data.length) return
      this.form.table_data = this.form.table_data.map(item => {
        // 不加判空会出现，先添加了sku图片，再添加缩略图，所有图片都更新
        if (isEmpty(item['图片'])) {
          item['图片'] = v
        }
        if (isEmpty(item['规格图片'])) {
          item['规格图片'] = v
        }
        return item
      })
    }
  },
  mounted() {
    this.getSpecPage()
  },
  methods: {
    async getSpecPage() {
      let [err, res] = await specPage()
      if (err) {
        console.log(err)
        return this.$message.error(err.msg || '获取默认规格列表失败')
      }
      let specList = res.data || []
      let mapArr = specList.map(item => {
        return [item.name, item.child.map(item => item.name)]
      })
      // this.attr_name_value = new Map([
      // Map 数据结构, 根据属性名获取对应属性值 返回数组
      // ['颜色', ['黑', '白', '红']],
      // ['尺寸', ['大', '中', '小']],
      // ['重量', ['500g', '1kg', '5kg']]
      // ])
      this.attr_name_value = new Map(mapArr)
      this.default_attr = [...this.attr_name_value.keys()]
    },
    async changeImage({ img }, row, idx, item_column) {
      row[item_column] = img
      if (this.form.sku_arr.length === 1) {
        this.$set(this.form.table_data, idx, { ...this.form.table_data[idx], 规格图片: img })
        return
      }
      //  多规格图片替换
      console.log(row.indexes)
      let str = row.indexes.substring(0, 2)
      let reg = new RegExp(`^${str}`)
      this.form.table_data.map(item => {
        if (reg.test(item.indexes)) {
          item['规格图片'] = img
        }
      })
    },
    handleInputDefaultParams() {
      if (!this.form.sku_arr?.length) return this.$message.warning('请先选择规格')
      if (!this.form.table_data.length) return
      this.form.table_data = this.form.table_data.map(item => {
        return {
          ...item,
          ...this.defaultParams
        }
      })
    },
    // 点击默认的规格按钮
    clickDefaultAttr(attr_name) {
      if (this.selectedAttr.includes(attr_name)) return
      this.form.sku_arr.push({ attr: attr_name, valueList: [...this.attr_name_value.get(attr_name)] }) //解决引用类型导致的问题
      console.log(this.form.sku_arr)
      this.manualGenerateTable()
      this.handleInputDefaultParams()
    },
    // 点击自定义里的确定 添加新的规格
    confirm() {
      if (!this.add_value) return
      let isHas = this.form.sku_arr.some(item => item.attr == this.add_value)
      if (isHas) return this.$message.warning('新添加的规格已经存在!')
      this.form.sku_arr.push({ attr: this.add_value, valueList: [''] })

      this.generateTableColumn()
      this.traverseSku()

      this.add_popover_bool = false
      this.add_value = ''
    },
    // 属性获得焦点时 得到旧的值 存一下
    attrFocus(oldVal) {
      old_attr = oldVal
    },
    // 属性失去焦点时
    attrBlur(newVal) {
      // console.log('attrBlur')
      // 如果 '新值等于旧值' 或者 '空' 什么也不做
      if (newVal === old_attr || newVal === '') return

      // 生成处理表头数据和表格数据
      this.generateTableColumn()
      this.traverseSku()
    },
    // 添加属性值
    async addAttributeValue(idx) {
      this.form.sku_arr[idx].valueList.push('')
      // 让新增的输入框获得焦点
      await this.$nextTick()
      this.$refs.attrValueInput[this.$refs.attrValueInput.length - 1].focus()
    },
    // 属性值获得焦点时 得到旧的值 在输入框失去焦点的时候, 如果值没有变化, 则什么也不做
    attrValueFocus(oldVal) {
      old_attr_value = oldVal
    },
    //属性值失去焦点时, 操作表格数据 (新版本 可以实现无限个规格)
    newAttrValueBlur(curr_attr, newVal) {
      if (newVal === old_attr_value) return
      //  这里根据规格生成的笛卡尔积计算出table中需要改变的行索引 ( 包含新增和修改 )
      let cartesian_arr = this.generateBaseData(this.form.sku_arr)
      // console.log(cartesian_arr)
      let change_idx_arr = [] // 需要被改变的行的索引
      for (let i in cartesian_arr) {
        if (cartesian_arr[i][curr_attr] === newVal) change_idx_arr.push(Number(i))
      }
      // 新的表格应该有的长度与现有的表格长度比较, 区分新增还是修改
      let length_arr = this.form.sku_arr.map(x => x.valueList.length)
      let new_table_length = length_arr.reduce((acc, curr_item) => acc * curr_item) // 新的表格数据长度 求乘积
      let old_table_length = this.form.table_data.length // 旧的表格数据长度

      // 如果是修改
      if (new_table_length === old_table_length) {
        this.form.table_data.forEach((item, index) => {
          if (change_idx_arr.includes(index)) this.form.table_data[index][curr_attr] = newVal
        })
        return
      }
      // 如果是新增
      if (new_table_length > old_table_length) {
        // 得到当前属性的当前值和其他规格的 sku_arr, 构造新的表格数据
        let other_sku_arr = this.form.sku_arr.map(item => {
          if (item.attr !== curr_attr) return item
          else return { attr: item.attr, valueList: [newVal] }
        })
        // 得到新增的表格数据
        let ready_map = this.generateBaseData(other_sku_arr)
        let new_table_data = this.mergeTableData(ready_map)
        change_idx_arr.forEach((item_idx, index) => {
          this.form.table_data.splice(item_idx, 0, new_table_data[index])
        })
        this.handleInputDefaultParams()
      }
    },
    // 删除属性
    deleteAttr(idx) {
      this.form.sku_arr.splice(idx, 1)
      // 生成处理表头数据和表格数据
      this.generateTableColumn()
      this.traverseSku()
    },
    // 删除属性值 四个参数：'一级数组索引', '二级索引', '属性名字', '属性值'  后两个参数用来删除行
    deleteSmall(idx, kdx, attr_name, attr_val) {
      this.form.sku_arr[idx].valueList.splice(kdx, 1)
      let data_length = this.form.table_data.length
      for (let i = 0; i < data_length; i++) {
        if (this.form.table_data[i][attr_name] == attr_val) {
          this.form.table_data.splice(i, 1)
          i = i - 1
          data_length = data_length - 1
        }
      }
    },
    // 手动生成表格
    manualGenerateTable() {
      if (this.form.sku_arr.length == 0) return
      this.generateTableColumn()
      this.traverseSku()
    },
    // 根据 `this.form.sku_arr` 生成表格列, `table_column` 用于 el-table-column 的 v-for
    async generateTableColumn() {
      this.table_column = this.form.sku_arr.map(x => x.attr).concat(base_column)
      /*
        不写 `$nextTick`会有bug, 没想明白为啥, 大概是vue懒得更新dom吧
        bug复现方式: 删除`$nextTick`后，勾选颜色，再勾选尺寸，再取消勾选颜色，观察el-table
      */
      await this.$nextTick()
      if (this.form.sku_arr.length != 0) this.table_column.splice(1, 0, '图片')
    },
    traverseSku() {
      let ready_map = this.generateBaseData(this.form.sku_arr)
      this.form.table_data = this.mergeTableData(ready_map)
      console.log(this.form.table_data)
    },
    // 重新实现笛卡尔积  入参是: this.form.sku_arr 传入的数组 '为空', '长度为1', '长度大于1' 三种情况 分别处理
    generateBaseData(arr) {
      if (arr.length === 0) return []
      if (arr.length === 1) {
        let [item_spec] = arr
        // console.log(item_spec)
        return item_spec.valueList.map((x, index) => {
          let spec = { [item_spec.attr]: x }
          return {
            ...spec,
            indexes: `${index}_`,
            ownSpec: JSON.stringify(spec)
          }
        })
      }
      if (arr.length >= 1) {
        return arr.reduce((accumulator, spec_item) => {
          let acc_value_list = Array.isArray(accumulator.valueList) ? accumulator.valueList : accumulator
          let item_value_list = spec_item.valueList
          let result = []
          for (let i in acc_value_list) {
            for (let j in item_value_list) {
              let temp_data = {}
              // 如果是对象
              if (acc_value_list[i].constructor === Object) {
                temp_data = {
                  ...acc_value_list[i],
                  [spec_item.attr]: item_value_list[j],
                  indexes: `${acc_value_list[i]['indexes']}${j}_`
                }
                // 否则如果是字符串
              } else {
                temp_data[accumulator.attr] = acc_value_list[i]
                temp_data[spec_item.attr] = item_value_list[j]
                temp_data.indexes = `${i}_${j}_`
              }
              let ownSpec = {}
              for (let [key, value] of Object.entries(temp_data)) {
                if (key !== 'indexes' && key !== 'ownSpec') {
                  ownSpec[key] = value
                }
              }
              temp_data.ownSpec = JSON.stringify(ownSpec)
              // console.log(ownSpec)
              // console.log(temp_data)
              result.push(temp_data)
            }
          }
          return result
        })
      }
    },
    // 合并 sku 与 '图片', '成本价', '库存', '销售价' , 返回整个表格数据数组
    mergeTableData(arr) {
      return arr.map((item, index) => {
        // 编辑
        if (this._skuList?.length) {
          return {
            ...item,
            ...this._skuList[index]
          }
          // 添加
        } else {
          let code = this.productCode || generateCode()
          defalut_param['规格编码'] = `${code}-${index}`
          defalut_param['图片'] = this.smallImg || ''
          defalut_param['规格图片'] = this.smallImg || ''
          return {
            ...item,
            ...defalut_param
          }
        }
      })
    },
    // 合并行
    spanMethod({ row, column, rowIndex, columnIndex }) {
      if (columnIndex == 0) {
        let key_0 = column.label
        let first_idx = this.form.table_data.findIndex(x => x[key_0] == row[key_0])
        const calcSameLength = () => this.form.table_data.filter(x => x[key_0] == row[key_0]).length
        first_column_rule = rowIndex == first_idx ? [calcSameLength(), 1] : [0, 0]
        return first_column_rule

        // 第二列的图片与第一列主规格使用相同合并规则 ( 恰好el-table的合并方法是横着走的 )
      } else if (columnIndex == 1) {
        return first_column_rule

        // 其他列
      } else {
        // 表格数据的每一项,
        const callBacks = (table_item, start_idx = 0) => {
          if (columnIndex < start_idx) return true
          let curr_key = this.table_column[start_idx]
          return table_item[curr_key] === row[curr_key] && callBacks(table_item, ++start_idx)
        }
        let first_idx = this.form.table_data.findIndex(x => callBacks(x))
        const calcSameLength = () => this.form.table_data.filter(x => callBacks(x)).length
        return rowIndex == first_idx ? [calcSameLength(), 1] : [0, 0]
      }
    },
    onSubmit() {
      return new Promise((resolve, reject) => {
        if (!this.form.sku_arr.length) {
          this.$message.error('请添加规格')
          reject({ msg: '请添加规格' })
          return
        }
        this.$refs.form.validate((valid, object) => {
          if (!valid) {
            errScroll(object)
            return reject({ msg: '规格信息填写不完整' })
          }

          let specTemplate = {}
          let skuList = []
          this.form.sku_arr.forEach(item => {
            specTemplate[item.attr] = item.valueList
          })
          console.log(this.form.sku_arr)
          this.form.table_data.forEach(item => {
            let default_param = {}
            for (let [key, value] of Object.entries(item)) {
              console.log(key, value)
              if (value === '') {
                this.$message.error(`有${key}对应值未填`)
                return
              }
              if (_paramName[key]) {
                default_param[_paramName[key]] = value
                // delete item[key] //删除中文属性
              }
            }
            skuList.push({
              ...item,
              ...default_param
            })
          })
          // console.log(specTemplate)
          // console.log(skuList)
          resolve({ specTemplate, skuList, msg: '商品规格保存成功' })
        })
      })
    }
  }
}
</script>
<style lang="less" scoped>
.sku_list_group {
  display: flex;
  align-items: center;
  padding: 15px;
  margin-bottom: 0 !important;
  height: 70px;
  border: 1px solid #e5e5e5;
  // border-bottom: 0;
  box-sizing: border-box;
  &:last-child {
    // border-bottom: 1px solid #e5e5e5;
  }
}
.sku_list_warp {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.el-icon-error {
  cursor: pointer;
}
::v-deep .defalut_param_wrap {
  display: flex;
  align-items: center;
  // margin-top: 10px;
  .el-form-item__content {
    display: flex;
    align-items: center;
  }
  .defalut_param_group {
    margin-bottom: 0 !important;
  }
  .defalut_input {
    margin-right: 20px;
    width: 180px;
    input {
      width: 100px;
    }
  }
}
.sku_table_wrap {
  margin-top: 10px;
  .label {
    display: flex;
    .title {
      margin-right: 20px;
    }
    .refreshTable {
      color: red;
      cursor: pointer;
    }
  }
}
.table_form_item {
  display: flex;
  justify-content: center;
}
::v-deep .header_cell_class {
  padding: 0;
}
.el-form-item .el-form-item {
  margin-bottom: 22px;
}
::v-deep .image_box {
  // width: 100%;
  display: flex;
  justify-content: center;
  .el-form-item__error {
    left: -50%;
    width: 100px;
  }
}
::v-deep .el-table tbody tr:hover > td {
  background-color: transparent;
}
::v-deep .uploadPlace-ctn {
  font-size: 12px !important;
  .el-icon-plus {
    font-size: 20px !important;
  }
}

::v-deep .el-table--enable-row-transition .el-table__body td {
  padding-bottom: 0;
}
</style>

import request from '@/utils/http'
import { awaitWrap } from '@/utils/util'
import { baseApi } from '@/config'

/**
 * 阿里巴巴授权
 * @param {*} route 页面路由 location.href
 */
export const aLiBabaAuth = ({ route }) => {
  console.log(`${baseApi}/alibaba/gotoAuth?${route}`)
  return `${baseApi}/alibaba/gotoAuth?route=${route}`
}

/**
 * 授权商家
 * @returns
 */
export const alibabaAuthorizationShop = ({ ...params }) => {
  return awaitWrap(
    request({
      url: `/alibaba/authorizationShop`,
      method: 'get',
      params,
      isLoading: true
    })
  )
}
/**
 * 修改授权商家状态
 * @param {} aliId
 * @param {} status
 * @returns
 */
export const alibabaAuthorizationShopStatus = ({ ...params }) => {
  return awaitWrap(
    request({
      url: `/alibaba/authorizationShop/status`,
      method: 'get',
      params,
      isLoading: true
    })
  )
}
/**
 * 刷新token
 * @param {} refreshToken
 * @returns
 */
export const alibabaRefreshToken = ({ ...params }) => {
  return awaitWrap(
    request({
      url: `/alibaba/refreshToken`,
      method: 'get',
      params,
      isLoading: true
    })
  )
}
/**
 * 获取商品
 * @param {} aliId
 * @returns
 */
export const alibabaGetList = ({ ...params }) => {
  return awaitWrap(
    request({
      url: `/alibaba/getList`,
      method: 'get',
      params,
      isLoading: true
    })
  )
}


/**
 * 获取授权商品的列表(page)
 * @param {*} pageNo 页码
 * @param {*} pageSize 页数
 * @param {*} keyWords 名称
 * @param {*} type 名称 1 商品 2 店铺
 * @param {*} synchro -1 全部  0 未同步 1已同步
 * @returns
 */
export const authGoodsPage = ({ ...params }) => {
  return awaitWrap(
    request({
      url: `/auth/goods/page`,
      method: 'get',
      params,
      isLoading: true
    })
  )
}
/**
 * 阿里授权商品数量
 * @returns
 */
export const authGoodsCount = () => {
  return awaitWrap(
    request({
      url: `/auth/goods/authGoods/count`,
      method: 'get',
      isLoading: true
    })
  )
}

/**
 * 获取授权商品详情
 * @param {*} "goodsId"
 * @returns
 */
export const authGoodsDetail = ({ ...params }) => {
  return awaitWrap(
    request({
      url: `/auth/goods/detail/${params.goodsId}`,
      method: 'get',
      isLoading: true
    })
  )
}
/**
 * 上架(添加)授权商品
 * @param {*} 
 * @returns
 */
export const authGoodsUp = ({ ...data }) => {
  return awaitWrap(
    request({
      url: `/auth/goods/up`,
      method: 'post',
      data,
      isLoading: true
    })
  )
}
/**
 * 下架(撤回)授权商品
 * @param {*} "goodsId"
 * @returns
 */
export const authGoodsDown = ({ ...params }) => {
  return awaitWrap(
    request({
      url: `/auth/goods/down/${params.goodsId}`,
      method: 'get',
      isLoading: true
    })
  )
}
/**
 * 批量下架(撤回)授权商品
 * @param {*} "params"
 * @returns
 */
export const authGoodsDownAll = ({ ...data }) => {
  return awaitWrap(
    request({
      url: `/auth/goods/down/all`,
      method: 'post',
      data,
      isLoading: true
    })
  )
}
/**
 * 回收站、删除还原商品
 * @param {*} "params"
 * @returns
 */
export const authGoodsChange = ({ ...data }) => {
  return awaitWrap(
    request({
      url: `/auth/goods/change`,
      method: 'post',
      data,
      isLoading: true
    })
  )
}

<template>
  <div class="">
    <div class="form-group">
      <!-- <div class="form-h">商品规格</div> -->
      <div class="form-item" v-for="(attr, index) in attrs" :key="`attr${index}`">
        <div class="form-title">
          <input type="text" name="" value="" v-model="attr.pName" placeholder="规格名" />
          <span class="delete" @click="toDelete(index)" v-if="!isId">×</span>
        </div>
        <ul class="form-list">
          <li v-for="(item, index2) in attr.spec" :key="`item${index2}`" class="margin-right-sm">
            <!--            <input class="spec-item" type="text" name="" value="" v-model="item.cName">-->
            <el-input v-model="item.cName" placeholder="请输入内容" size="medium">
              <i slot="suffix" class="el-input__icon el-icon-delete" @click="attribute(index, index2)" v-if="!isId"></i>
            </el-input>
          </li>
          <li class="margin-left-sm" v-if="!isId">
            <el-button class="cols" type="primary" plain size="medium" name="" @click="adding(index)">添加</el-button>
          </li>
        </ul>
      </div>
      <div class="form-btn-group" v-if="!isId">
        <!--        <button class="btn" type="button" name="" @click="addItem">添加规格项目</button>-->
        <el-button class="cols" type="primary" plain name="" @click="addItem">添加规格项目</el-button>
      </div>
      <div class="form-table" v-show="tableData">
        <div class="stock-title">商品库存</div>
        <table class="table-sku" border="1px solid #ccc">
          <thead class="text-center">
            <tr>
              <td v-for="(list, index) in tableData" :key="`list${index}`">{{ list['pName'] }}</td>
              <td>零售价</td>
              <td>销售价</td>
              <td>成本价</td>
              <td>规格编码</td>
              <td>库存</td>
              <td>图片</td>
            </tr>
          </thead>
          <tbody class="text-center">
            <tr v-for="(row, index) in rows" :key="`row${index}`">
              <template v-for="(item, index2) in tableData">
                <td v-if="!((row - 1) % item['rowspan'])" :rowspan="item['rowspan']" :key="`item${index2}`">
                  {{ item | getName(row) }}
                </td>
              </template>
              <td>
                <input type="text" v-model="tableList[row - 1]['retailPrice']" v-numbarFloat />
              </td>
              <td>
                <input type="text" v-model="tableList[row - 1]['salesPrice']" v-numbarFloat />
              </td>
              <td>
                <input type="text" v-model="tableList[row - 1]['costPrice']" v-numbarFloat />
              </td>
              <td>
                <input type="text" v-model="tableList[row - 1]['skuCode']" />
              </td>
              <td>
                <input :disabled="isId" type="text" v-model="tableList[row - 1]['stock']" v-Int />
              </td>
              <td>
                <upload-place v-model="tableList[row - 1]['image']" :styleObject="{ width: '100px', height: '100px' }" />
              </td>
            </tr>
          </tbody>
        </table>
        <div class="form-btn-group">
          <!--          <button class="btn" type="button" name="" @click="toConfirm">确认</button>-->
          <el-button class="cols" type="danger" name="" @click="toConfirm">保存</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import uploadPlace from '@/components/upload/uploadPlace.vue'
import uploadPlaceFdta from '@/components/upload/uploadPlaceFdta.vue'
import { goodsGet } from '@/api/GoodsController'
import { authGoodsDetail } from '@/api/AlibabaController'
let paramName = {
  stock: '库存',
  image: '图片',
  salesPrice: '零售价',
  costPrice: '成本价',
  retailPrice: '原价',
  skuCode: '规格编码'
}
export default {
  components: {
    uploadPlace,
    uploadPlaceFdta
  },
  name: 'spec',
  props: {
    smallImg: String
  },
  data() {
    return {
      isId: this.$route.query.goodsId, // 判断是否是编辑
      attrs: [],
      ss: [],
      dialogTableVisible: true,
      goodsInfo: {},
      calcPriceTI: [], // 编辑接口返回的sku数据
      specTeDat: [], // 用于渲染商品规格
      edbDialog: {
        title: 'E店宝商品',
        skuId: '',
        dialogVisible: false
      }
    }
  },
  watch: {
    smallImg(val) {
      // console.log(val)
      if (this.tableList && this.tableList.length) {
        // console.log(this.tableList)
      }
    }
  },
  computed: {
    tableData: function() {
      var attrs = this.attrs
      var len = attrs.length
      // let that = this
      if (len === 0) {
        return
      }
      var tData = []
      // 初始化tableData
      for (var i = 0; i < len; i++) {
        var row = {}
        row['pName'] = attrs[i]['pName']
        row['spec'] = []
        row['price'] = {}
        row['number'] = {}
        var len2 = attrs[i]['spec'].length
        var specLen = 0
        for (var j = 0; j < len2; j++) {
          var spe = {}
          var cName = attrs[i]['spec'][j]['cName']
          if (!cName) {
            continue
          }
          ++specLen
          spe['cName'] = cName
          row['spec'].push(spe)
        }
        row['specLen'] = specLen
        tData.push(row)
      }
      // 获取rowspan
      for (var k = 0, len3 = tData.length; k < len3; k++) {
        var rowspan = 1
        for (var k1 = k + 1; k1 < len3; k1++) {
          var kSpecLen = tData[k1]['specLen'] || 1
          rowspan *= kSpecLen
        }
        tData[k]['rowspan'] = rowspan
      }
      return tData
    },
    rows: function() {
      if (!this.tableData) {
        return
      }
      var rows = 1
      var tableData = this.tableData
      var len = tableData.length
      for (var i = 0; i < len; i++) {
        var specLen = tableData[i]['specLen'] || 1
        rows *= specLen
      }
      // 每条rowspan都为1情况
      if (rows === 1) {
        return tableData[0]['spec'].length
      }
      return rows
    },
    tableList: function() {
      // let that = this
      if (!this.tableData) {
        return
      }
      var rows = this.rows
      console.log(rows)
      var tList = []
      var srcData = this.tableData

      console.log(srcData)
      for (var i = 0; i < rows; i++) {
        var listItem = {}
        var keyList = ''
        // 构建动态项
        for (var j = 0; j < srcData.length; j++) {
          // 构造第一类目
          var key = srcData[j]['pName']
          var rowspan = srcData[j]['rowspan']
          var len = srcData[j]['specLen']
          if (!len) {
            continue
          }
          var spec = srcData[j]['spec']
          var index = parseInt(i / rowspan) % len
          listItem[key] = spec[index]['cName']
          // console.log(spec[index]['cName'] + '------' + index)
          keyList += index + '_'
        }
        // 构建固定项(price,number)
        // listItem['price'] = ''
        listItem['indexes'] = keyList // 规格下标
        if (this.$route.query.goodsId) {
          listItem['skuId'] = this.calcPriceTI[i].skuId // sku的id
          listItem['stock'] = this.calcPriceTI[i].stock // 库存
          listItem['image'] = this.calcPriceTI[i].image // 图片
          listItem['salesPrice'] = this.calcPriceTI[i].salesPrice // 销售价
          listItem['costPrice'] = this.calcPriceTI[i].costPrice // 成本价
          listItem['retailPrice'] = this.calcPriceTI[i].retailPrice // 零售价
          listItem['skuCode'] = this.calcPriceTI[i].skuCode // 规格编码
        } else {
          console.log(listItem['image'])
          listItem['stock'] = '' // 库存
          listItem['image'] = this.smallImg ?? '' // 图片
          listItem['salesPrice'] = '' // 销售价
          listItem['costPrice'] = '' // 成本价
          listItem['retailPrice'] = '' // 零售价
          listItem['skuCode'] = '' // 规格编码
        }
        listItem['dialogTableVisible'] = false // 控制弹框
        tList.push(listItem)
      }
      // console.log(tList)

      return tList
    }
  },
  mounted() {
    // console.log(this.attrs)
    // console.log(this.tableData)
    // console.log(this.rows)
    // console.log(this.tableList)
  },
  created() {
    this.goodsId = this.$route.query.goodsId ?? ''
    this.isAuthGoods = this.$route.query.isAuthGoods ?? ''
    // 商品列表编辑按钮点击跳转之后的回显
    if (this.goodsId) {
      this.getGoodsDetail()
    } else {
      // this.$message.error('获取规格失败')
    }
  },
  filters: {
    getName: function(obj, index) {
      if (obj) {
        var r = parseInt((index - 1) / obj['rowspan'])
        var l = obj['specLen'] || 1
        var key = r % l
        return obj['spec'] && obj['spec'][key] && obj['spec'][key]['cName']
      }
    }
  },

  methods: {
    async getGoodsDetail() {
      let [err, res] = []
      // 授权商品
      if (this.isAuthGoods) {
        ;[err, res] = await authGoodsDetail({ goodsId: this.goodsId })
      } else {
        ;[err, res] = await goodsGet({ goodsId: this.goodsId })
      }
      if (err) {
        console.log(err)
        return this.$message.error(err.msg || '获取规格失败')
      }
      // console.group('sku值')
      // console.log(res)
      this.goodsInfo = res.data
      let att = []
      this.specTeDat = JSON.parse(res.data.specTemplate)
      let specTe = this.specTeDat
      Object.keys(specTe).forEach(item => {
        let obj = {
          pName: '',
          rowspan: 1,
          img: '',
          spec: []
        }
        let itess = []
        specTe[item].forEach(items => {
          let ite = {}
          ite.cName = items
          itess.push(ite)
        })
        obj.pName = item
        obj.spec = itess
        att.push(obj)
      })
      this.attrs = this.$deepCopy(att)
      this.calcPriceTI = this.$deepCopy(res.data.skuList)
    },
    addItem: function() {
      var obj = {
        pName: '',
        rowspan: 1,
        img: '',
        spec: [{ cName: '' }]
      }
      if (this.attrs.length >= 3) {
        return
      }
      this.attrs.push(obj)
    },
    // 添加规格
    adding(index) {
      let ss = { cName: '' }
      this.attrs[index].spec.push(ss)
      // console.log(this.attrs)
    },
    // 删除规格
    attribute(index, index2) {
      this.attrs[index].spec.splice(index2, 1)
    },

    toDelete: function(index) {
      this.attrs.splice(index, 1)
    },
    // 生成规格
    priceit() {},
    // 确定
    toConfirm: function() {
      // let cargoes = []
      this.tableList.forEach(item => {
        let takes = {}
        this.attrs.forEach(items => {
          takes[items.pName] = item[items.pName]
        })
        item.ownSpec = JSON.stringify(takes)
      })
      // 处理规格数据
      let specTemplate = {}
      this.attrs.forEach(items => {
        specTemplate[items.pName] = []
        items.spec.forEach(item => {
          specTemplate[items.pName].push(item.cName)
        })
      })
      // 向父组件传值
      for (let item of this.tableList) {
        for (let key in item) {
          if (item[key] === '') {
            // this.$message('商品规格不可以为空哦!')
            this.$message(`商品<${paramName[key]}>不可以为空哦!`)
            return
          }
        }

        if (!item.skuCode) {
          this.$message('规格编码不可以为空哦')
          return
        }
        // if (!item.barCode) {
        //   this.$message('所有规格商品需要绑定E店宝')
        //   return
        // }
      }
      this.$message({
        message: '保存成功',
        type: 'success'
      })
      console.log(this.tableList)
      console.log(specTemplate)
      // return
      this.$emit('myEvent', { tableList: this.tableList, specTemplate })
    }
  }
}
</script>

<style lang="css">
/**reset*/
button,
input {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  outline: none;
}

.btn {
  padding: 4px 12px;
  margin-bottom: 0;
  font-size: 14px;
  color: #333;
  vertical-align: middle;
  cursor: pointer;
  background-color: #f8f8f8;
  border: 1px solid #ddd;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}

.btn.active,
.btn:active,
.btn:focus,
.btn:hover {
  text-decoration: none;
  color: #333;
  background-color: #fcfcfc;
  border-color: #ccc;
}

/*table*/
table {
  border: 0;
}

table.table-sku {
  width: 100%;
  background-color: #fff;
  text-align: left;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

table.table-sku td {
  border: 1px solid #e5e5e5;
  padding: 8px;
  vertical-align: middle;
  text-align: center;
}

table.table-sku td input {
  padding: 10px;
  border: 1px solid #ccc;
}

/**/
.form-title {
  background: #f8f8f8;
  padding: 10px;
  position: relative;
}

.form-title .label {
  color: #999;
}

.form-title .delete {
  width: 20px;
  height: 20px;
  line-height: 20px;
  border: 1px solid #ccc;
  border-radius: 50%;
  position: absolute;
  right: 15px;
  top: 50%;
  margin-top: -10px;
  text-align: center;
  color: #fff;
  background: #ccc;
  cursor: pointer;
}

.form-title input {
  background: #fff;
  border: 1px solid #ccc;
  padding: 10px;
}

.form-list {
  padding: 10px;
  margin-top: 0;
}

.form-list li {
  display: inline-block;
  margin-top: 10px;
}

.spec-item {
  width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.form-list,
.form-title {
  text-align: left;
}

.form-list input {
  background: #fff;
  margin-right: 10px;
  border: 1px solid #ccc;
  padding: 10px;
}

.form-group {
  border: 1px solid #ccc;
  padding: 10px;
}

.form-table {
  margin-top: 50px;
}

.form-btn-group {
  margin-top: 20px;
  background: #f8f8f8;
  padding: 10px;
}

.stock-title,
.form-h {
  height: 40px;
  line-height: 40px;
}
#uploadPlace {
  text-align: center;
}
</style>

<template>
  <!--    上传图片组件-->
  <div :id="id" class="uploadPlace">
    <!-- <div> -->
    <div class="uploadPlace-uploaders " :style="{ ...styleObjectZ, borderRadius }">
      <img :style="{ borderRadius }" v-show="companyLogo" :src="companyLogo" alt="" class="uploaders-img" />
      <div class="uploadPlace-ctn">
        <div class="uploadPlace-ctn-post">
          <i v-if="companyLogo === ''" class="el-icon-plus"></i>
          <p v-if="companyLogo === ''">{{ imgTextDta }}</p>
        </div>
      </div>
      <input :disabled="isUpload" class="uploaders-file" type="file" accept="image/*" name="file" ref="avatarInput" @change="uploadsPlace" @mouseover="mouseoverDta" />
    </div>
    <!-- </div> -->
  </div>
</template>

<script>
import axios from 'axios'
import { baseApi } from '@/config/index'
import store from '@/store/index.js'

export default {
  name: 'uploadPlace',
  props: {
    id: {
      type: String,
      default: function() {
        return 'uploadPlace-' + +new Date() + ((Math.random() * 1000).toFixed(0) + '')
      }
    },
    isUpload: {
      type: Boolean,
      default: false
    },
    value: {
      type: String,
      default: ''
    },
    styleObject: {
      type: Object,
      default: function() {
        return {
          width: '150px',
          height: '150px'
        }
      }
    },
    toNumber: {
      type: Number,
      default: 1
    }, // 组件数量
    imgText: {
      type: String,
      default: '上传图片'
    }, // 组件默认文字
    borderRadius: {
      type: String,
      default: '10px'
    }
  },
  data() {
    return {
      styleObjectZ: this.styleObject,
      lineHeightDta: {
        lineHeight: this.styleObject.height
      },
      config: {
        // 图片的请求头
        headers: {
          'Content-Type': 'multipart/form-data',
          token: store.state.Login.loginInfo.token
        }
      },
      companyLogo: this.value, // 图片地址
      NumberShL: this.toNumber, // 组件数量
      imgTextDta: this.imgText, // 文字说明
      ismouseover: false
    }
  },
  watch: {
    value: function() {
      this.companyLogo = this.value
    }
  },
  methods: {
    // 上传图片
    uploadsPlace(e) {
      const loading = this.$loading({
        lock: true,
        text: '上传中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      // 上传图片事件
      let fromData = new FormData()
      // let files = this.$refs[avatarInput][0].files
      let files = e.target.files
      // 图片真实大小以及是否符合规定尺寸
      let isImageSize = this.beforeAvatarUpload(files[0])
      // return
      fromData.append('file', files[0])
      if (files.length === 0) {
        loading.close()
        return
      }
      if (/\.(jpe?g|png|gif)$/i.test(files[0].name)) {
        // 上传图片
        axios
          .post(`${baseApi}/upload/uploadImg`, fromData, this.config)
          .then(res => {
            loading.close()
            if (res.data.code === 200) {
              this.$message({
                showClose: true,
                message: '上传成功',
                type: 'success'
              })
              this.companyLogo = res.data.data
              this.$emit('changeImage', { ...isImageSize, img: res.data.data })
              this.$emit('input', res.data.data)
            } else {
              this.$message('抱歉!请您稍后重试哦')
            }
            // console.log(res)
          })
          .catch(err => {
            loading.close()
            this.$message('网络异常!请您稍后重试哦')
            console.log(err)
          })
      }
    },
    // 上传图片之前
    beforeAvatarUpload(file) {
      if (!file) return
      const isImg = file.type === 'image/jpeg' || file.type === 'image/png'
      const isLt2M = file.size / 1024 / 1024 < 5
      let imgSize = {}
      let reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = function() {
        //让页面中的img标签的src指向读取的路径
        let img = new Image()
        img.src = reader.result
        if (img.complete) {
          imgSize.width = img.width
          imgSize.height = img.height
        } else {
          img.onload = function() {
            imgSize.width = img.width
            imgSize.height = img.height
          }
        }
      }
      return { imgSize }
    },
    // 鼠标进入
    mouseoverDta() {
      // 有图片地址是才置为true
      if (this.companyLogo) {
        this.ismouseover = true
      }
    },
    // 鼠标离开
    mouseoutDta() {
      this.ismouseover = false
    }
  }
}
</script>

<style scoped lang="less">
.uploadPlace {
  /*图片上传*/
  .uploadPlace-uploaders {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    text-align: center;
    border: 1px dashed #bbb;
    border-radius: 10px;

    .uploaders-file {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 3;
      display: flex;
      width: 100%;
      height: 100%;
      outline: fuchsia;
      opacity: 0;
      cursor: pointer;
    }

    .uploadPlace-ctn {
      // position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      font-size: 14px;
      .uploadPlace-ctn-post {
        // position: absolute;
        // top: 50%;
        // left: 50%;
        // transform: translate(-50%, -50%);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        line-height: 1.15;
        p {
          margin: 5px 0 0;
          color: #999;
        }
        .el-icon-plus {
          font-size: 30px;
          /*line-height: 150px;*/
        }
      }
    }

    .uploaders-img {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      width: 100%;
      height: 100%;
      border-style: none;
      border-radius: 10px;
    }
  }
  /*图片上传*/
}
</style>

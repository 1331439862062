<!-- 商品管理 -->
<template>
  <div class="container">
    <div style="cursor: pointer;" @click="$router.go(-1)"><i class="arrow_back el-icon-back"></i>返回</div>
    <TitleBarBaseVue />
    <el-form :model="formData" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
      <el-row :gutter="40">
        <el-col :span="8">
          <el-form-item label="商品名称" prop="name" required>
            <el-input v-model="formData.name" placeholder="请输入商品名称"></el-input>
          </el-form-item>
          <el-form-item label="上架下架" prop="status" required>
            <el-select v-model="formData.status" placeholder="请选择上架下架">
              <el-option label="下架" :value="0"></el-option>
              <el-option label="上架" :value="1"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="一级分类" prop="firstLevelCategoryId" required>
            <el-select @change="handleSelectFirst" v-model="formData.firstLevelCategoryId" placeholder="选择一级类别">
              <el-option v-for="item in firstList" :key="item.categoryId" :label="item.name" :value="item.categoryId"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="商品单位" prop="spec" required>
            <el-select v-model="formData.spec" placeholder="选择单位">
              <el-option v-for="item in unitList" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="商品编码" prop="productCode" required>
            <el-input v-model="formData.productCode" placeholder="请输入商品编码"></el-input>
          </el-form-item>
          <el-form-item label="二级分类" prop="secondLevelCategoryId" required>
            <el-select :disabled="!formData.firstLevelCategoryId" v-model="formData.secondLevelCategoryId" placeholder="选择二级类别">
              <el-option v-for="item in secondList" :key="item.categoryId" :label="item.name" :value="item.categoryId"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item label="显示端口" prop="ports" required>
        <el-checkbox-group v-model="formData.ports">
          <el-checkbox label="0">小程序</el-checkbox>
          <el-checkbox label="1">H5</el-checkbox>
          <el-checkbox label="2">APP</el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item label="缩略图片" prop="smallImg" required>
        <uploadPlace v-model="formData.smallImg" />
        <div class="share_desc">
          仅支持JPG、JPEG、BMP、PNG格式的图片（建议使用200*200px的规格）
        </div>
      </el-form-item>
      <el-form-item label="商品主图" prop="imgs" required>
        <div class="goods_main_img">
          <uploadPlaceFdta v-model="formData.imgs[0]" :isDsplay="true" style="margin-right:10px" />
          <uploadPlaceFdta v-model="formData.imgs[1]" :isDsplay="true" style="margin-right:10px" />
          <uploadPlaceFdta v-model="formData.imgs[2]" :isDsplay="true" style="margin-right:10px" />
          <uploadPlaceFdta v-model="formData.imgs[3]" :isDsplay="true" style="margin-right:10px" />
          <uploadPlaceFdta v-model="formData.imgs[4]" :isDsplay="true" style="margin-right:10px" />
        </div>
        <div class="share_desc">仅支持JPG、JPEG、BMP、PNG格式的图片（建议使用400*400px的规格）。最多上传5张</div>
      </el-form-item>
      <!-- <el-form-item label="商品规格" required>
        <spec @myEvent="handleSkuUpload" :smallImg="formData.smallImg" />
      </el-form-item> -->
      <el-form-item label="商品规格" required>
        <GoodsSpec ref="spec" :productCode="formData.productCode" :specTemplate="formData.specTemplate" :skuList="formData.skuList" :smallImg="formData.smallImg" />
      </el-form-item>
      <el-form-item label="商品描述" style="margin-top:20px">
        <!-- <tinymce-editor ref="editor" v-model="formData.details" :height="300"> </tinymce-editor> -->
        <Tinymce v-model="formData.details" :height="500" />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')">保存</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import TitleBarBaseVue from '@/components/TitleBar/TitleBarBase.vue'
import { goodsGet, goodsUpdate, categoryList, categorySecondList } from '@/api/GoodsController'
import { authGoodsDetail, authGoodsUp } from '@/api/AlibabaController'
import uploadPlace from '@/components/upload/uploadPlace.vue'
import uploadPlaceFdta from '@/components/upload/uploadPlaceFdta.vue'
import spec from '@/components/spec/index.vue' // 商品sku组件
import GoodsSpec from '@/components/GoodsSpec/index.vue' // 商品sku组件
import TinymceEditor from '@/components/RichText/tinymce-editor.vue'
import Tinymce from '@/components/Tinymce/index.vue'
import { errScroll } from '@/utils/util'
export default {
  components: { TitleBarBaseVue, uploadPlace, uploadPlaceFdta, GoodsSpec, spec, TinymceEditor, Tinymce },
  data() {
    let imgs = (rule, value, callback) => {
      // let list = value.filter(item => item)
      if (!value.length) {
        return callback(new Error('请上传商品主图'))
      }
      callback()
    }
    return {
      formData: {
        goodsId: '',
        name: '',
        ports: [],
        status: 0,
        spec: '',
        productCode: '',
        smallImg: '',
        imgs: [],
        // imgs: new Array(5),
        imgList: [],
        firstLevelCategoryId: null,
        secondLevelCategoryId: null,
        details: ''
      },
      rules: {
        name: [{ required: true, message: '请输入活动名称', trigger: 'blur' }],
        productCode: [{ required: true, message: '请输入商品编码', trigger: 'blur' }],
        spec: [{ required: true, message: '请选择商品单位', trigger: 'blur' }],
        status: [{ required: true, message: '请选择上架下架', trigger: 'change' }],
        firstLevelCategoryId: [{ required: true, message: '选择一级类别', trigger: 'change' }],
        secondLevelCategoryId: [{ required: true, message: '选择二级类别', trigger: 'change' }],
        ports: [{ required: true, message: '请选择显示端口', trigger: 'blur' }],
        smallImg: [{ required: true, message: '请上传缩略图', trigger: 'change' }],
        imgs: [{ validator: imgs, message: '请上传商品主图', trigger: 'change' }]
      },
      unitList: [
        { label: '个', value: 1 },
        { label: '件', value: 2 },
        { label: '瓶', value: 3 },
        { label: '套', value: 4 },
        { label: '双', value: 5 },
        { label: '盒', value: 6 },
        { label: '条', value: 7 },
        { label: '支', value: 8 },
        { label: '箱', value: 9 },
        { label: '包', value: 10 },
        { label: '片', value: 11 },
        { label: '罐', value: 12 }
      ],
      firstList: [],
      secondList: [],
      skuList: [], // 保存子组件传回的数据
      specTemplate: [] // 保存子组件传回的数据
    }
  },
  mounted() {
    this.formData.goodsId = this.$route.query.goodsId ?? ''
    this.isAuthGoods = this.$route.query.isAuthGoods ?? ''
    if (this.formData.goodsId) {
      this.getGoodsDetail()
    }
    this.getCategoryList()
  },
  methods: {
    // 自定义商品
    async getGoodsDetail() {
      let [err, res] = []
      if (this.isAuthGoods) {
        // 授权商品
        ;[err, res] = await authGoodsDetail({ goodsId: this.formData.goodsId })
      } else {
        ;[err, res] = await goodsGet({ goodsId: this.formData.goodsId })
      }
      if (err) {
        console.log(err)
        return this.$message.error(err.msg || '获取详情失败')
      }
      console.log(res)
      this.formData = res.data || {}
      if (this.formData.firstLevelCategoryId) {
        this.handleSelectFirst(this.formData.firstLevelCategoryId)
      }
    },
    async getCategoryList() {
      let [err, res] = await categoryList()
      if (err) {
        console.log(err)
        return this.$message.error(err.msg || '获取失败')
      }
      this.firstList = res.data || []
    },
    async handleSelectFirst(value) {
      let [err, res] = await categorySecondList({ categoryId: value })
      if (err) {
        console.log(err)
        return this.$message.error(err.msg || '获取二级列表失败')
      }
      // console.log(res)
      // this.formData.secondLevelCategoryId = null
      this.secondList = res.data || []
    },
    handleSkuUpload(e) {
      // console.log(e)
      this.skuList = e.tableList
      this.specTemplate = e.specTemplate
    },
    async submitForm(formName) {
      let [error, response] = await this.$awaitWrap(this.$refs['spec'].onSubmit())
      if (error) return
      console.log(error)
      console.log(console.log(response))
      // console.log(this.formData)
      let params = {}
      // this.formData.imgs = this.formData.imgs.filter(item => item).join(',')
      this.$refs[formName].validate(async (valid, object) => {
        if (!valid) {
          errScroll(object)
          return this.$message.error('必填项要填')
        }
        params.skuList = response.skuList
        params.specTemplate = JSON.stringify(response.specTemplate)
        // console.log(params, this.formData)
        let [err, res] = []
        console.log(this.isAuthGoods)
        if (this.isAuthGoods) {
          ;[err, res] = await authGoodsUp(Object.assign({}, this.formData, params))
        } else {
          ;[err, res] = await goodsUpdate(Object.assign({}, this.formData, params))
        }
        if (err) {
          console.log(err)
          return this.$message.error(err.msg || '保存失败')
        }
        this.$message.success(res.msg || '保存成功')
        this.$router.go(-1)
      })
    }
  }
}
</script>
<style lang="less" scoped>
.el-select {
  width: 100%;
}
.goods_main_img {
  display: flex;
}
</style>

<template>
  <!--    上图片组件带有删除和修改的-->
  <div id="uploadPlaceFdta">
    <div class="flex-start">
      <div class="uploadPlace-uploaders" :style="{ ...styleObjectZ, borderRadius }" v-for="item in toNumber" :key="item">
        <img :style="{ borderRadius }" v-show="companyLogo" :src="companyLogo" alt class="uploaders-img" />
        <div class="uploadPlace-ctn">
          <div class="uploadPlace-ctn-post">
            <i v-if="!companyLogo" class="el-icon-plus"></i>
            <p>{{ imgTextDta }}</p>
          </div>
        </div>
        <input class="uploaders-file" type="file" accept="image/*" name="file" ref="avatarInput" @change="uploadsPlace($event)" @mouseover="mouseoverDta" />
        <div class="uploadPlace-mask" v-show="companyLogo && ismouseover && isDsplayFu" @mouseout="mouseoutDta">
          <p class="edit" @mouseover="mouseoverDta">
            <input class="span-input" type="file" accept="image/*" name="file" @change="uploadsPlaceEdit" @mouseover="mouseoverDta" />
            <i class="el-icon-edit" @mouseover="mouseoverDta"></i>
          </p>
          <p class="delete">
            <i class="el-icon-delete" :style="lineHeightDta" @click="iconDelete($event)" @mouseover="mouseoverDta"></i>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { baseApi } from '@/config'
import store from '@/store/index.js'
export default {
  name: 'uploadPlaceFdta',
  props: {
    value: {
      type: String
    },
    styleObject: {
      type: Object,
      default: function() {
        return {
          width: '150px',
          height: '150px'
        }
      }
    },
    toNumber: {
      type: Number,
      default: 1
    }, // 组件数量
    imgText: {
      type: String,
      default: '上传图片'
    }, // 组件默认文字
    isDsplay: {
      type: Boolean,
      default: false
    },
    borderRadius: {
      type: String,
      default: '10px'
    }
  },
  data() {
    return {
      styleObjectZ: this.styleObject,
      lineHeightDta: {
        lineHeight: this.styleObject.height
      },
      config: {
        // 图片的请求头
        headers: {
          'Content-Type': 'multipart/form-data',
          token: store.state.Login.loginInfo.token
        }
      },
      companyLogo: this.value, // 图片地址
      NumberShL: this.toNumber, // 组件数量
      imgTextDta: this.imgText, // 文字说明
      ismouseover: true,
      isDsplayFu: this.isDsplay // 控制修改删除图标是否显示
    }
  },
  watch: {
    value: function() {
      this.companyLogo = this.value
      console.log(this.value)
      console.log(this.companyLogo)
    }
  },
  methods: {
    // 上传图片
    uploadsPlace(e) {
      // console.log(e);
      const loading = this.$loading({
        lock: true,
        text: '上传中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      // 上传图片事件
      let fromData = new FormData()
      // let files = this.$refs[avatarInput][0].files
      let files = e.target.files
      // 图片真实大小以及是否符合规定尺寸
      let isImageSize = this.beforeAvatarUpload(files[0])
      // console.log(files);
      fromData.append('file', files[0])
      if (files.length === 0) {
        loading.close()
        return
      }
      if (/\.(jpe?g|png|gif)$/i.test(files[0].name)) {
        // 上传图片
        axios
          .post(`${baseApi}/upload/uploadImg`, fromData, this.config)
          .then(res => {
            loading.close()
            if (res.data.code === 200) {
              this.$message({
                showClose: true,
                message: '上传成功',
                type: 'success'
              })
              this.companyLogo = res.data.data
              console.log(this.companyLogo)
              this.$emit('changeImage', { ...isImageSize, img: res.data.data })
              this.$emit('input', this.companyLogo)
            } else {
              this.$message('抱歉!请您稍后重试哦')
            }
            // console.log(res)
          })
          .catch(err => {
            loading.close()
            this.$message('网络异常!请您稍后重试哦')
            console.log(err)
          })
      }
    },
    // 上传图片之前
    beforeAvatarUpload(file) {
      if (!file) return
      const isImg = file.type === 'image/jpeg' || file.type === 'image/png'
      const isLt2M = file.size / 1024 / 1024 < 5
      let imgSize = {}
      let reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = function() {
        //让页面中的img标签的src指向读取的路径
        let img = new Image()
        img.src = reader.result
        if (img.complete) {
          imgSize.width = img.width
          imgSize.height = img.height
        } else {
          img.onload = function() {
            imgSize.width = img.width
            imgSize.height = img.height
          }
        }
      }
      return { imgSize }
    },
    // 鼠标进入
    mouseoverDta() {
      console.log(this.companyLogo)
      // 有图片地址是才置为true
      if (this.companyLogo) {
        this.ismouseover = true
      }
    },
    // 鼠标离开
    mouseoutDta() {
      this.ismouseover = false
    },
    // 删除图标
    iconDelete(event) {
      this.$emit('input', '')
    },
    // 点击修改按钮的上传图片
    uploadsPlaceEdit(e) {
      console.log(e)

      const loading = this.$loading({
        lock: true,
        text: '上传中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      // 上传图片事件
      let fromData = new FormData()
      // let files = this.$refs[avatarInput][0].files
      let files = e.target.files
      let isImageSize = this.beforeAvatarUpload(files[0])
      // console.log(e)
      // 获取id
      let id = e.path[6].id
      fromData.append('file', files[0])
      if (files.length === 0) {
        loading.close()
        return
      }
      if (/\.(jpe?g|png|gif)$/i.test(files[0].name)) {
        // 上传图片
        axios
          .post(`${baseApi}/upload/uploadImg`, fromData, this.config)
          .then(res => {
            loading.close()
            if (res.data.code === 200) {
              this.$message({
                showClose: true,
                message: '上传成功',
                type: 'success'
              })
              this.companyLogo = res.data.data
              this.$emit('changeImage', { ...isImageSize, img: res.data.data })
              this.$emit('modifyId', id, res.data.data, files[0])
              this.$emit('input', res.data.data)
            } else {
              this.$message('抱歉!请您稍后重试哦')
            }
            // console.log(res)
          })
          .catch(err => {
            loading.close()
            this.$message('网络异常!请您稍后重试哦')
            console.log(err)
          })
      }
    }
  }
}
</script>

<style scoped lang="less">
.flex-start {
  display: flex;
  /*justify-content: start;*/
  flex-direction: row;
  align-items: center;
}
#uploadPlaceFdta {
  /*图片上传*/
  .uploadPlace-uploaders {
    position: relative;
    text-align: center;
    background-color: #fbfdff;
    border: 1px dashed #c0ccda;
    border-radius: 6px;

    .uploaders-file {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 3;
      display: flex;
      width: 100%;
      height: 100%;
      outline: fuchsia;
      opacity: 0;
      cursor: pointer;
    }

    .uploadPlace-ctn {
      position: relative;
      width: 100%;
      height: 100%;
      font-size: 14px;
      .uploadPlace-ctn-post {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        p {
          margin: 5px 0 0;
          color: #999;
          line-height: 1.15;
        }
        .el-icon-plus {
          font-size: 30px;
          /*line-height: 150px;*/
        }
      }
    }

    .uploaders-img {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      width: 100%;
      height: 100%;
      border-style: none;
      border-radius: 10px;
    }

    .uploadPlace-mask {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 10;
      width: 100%;
      height: 100%;
      font-size: 24px;
      background-color: rgba(0, 0, 0, 0.5);
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      i {
        color: #f0f0f0;
        cursor: pointer;
      }
      .edit,
      .delete {
        position: relative;
        margin: 5px;
      }
      .span-input {
        position: absolute;
        top: 1px;
        left: 0;
        width: 24px;
        height: 24px;
        opacity: 0;
        cursor: pointer;
        background: #000;
      }
    }
  }
  /*图片上传*/
}

/deep/ .el-icon-plus[data-v-02c440d3] {
  color: #999;
}
</style>

import request from '@/utils/http'
import { awaitWrap } from '@/utils/util'

/**
 * 获取列表
 * @param {*} "keyWords" 
 * @returns
 */
export const specPage = (params) => {
  return awaitWrap(
    request({
      url: `/spec/page`,
      method: 'get',
      params,
      isLoading: false
    })
  )
}

/**
 * 添加/修改SKU
 * {
  "child": [
    {
      "name": "",
      "valueId": 0
    }
  ],
    "name": "",
    "specId": 0
  }
 * @returns
 */
export const specUpdate = (data) => {
  return awaitWrap(
    request({
      url: `/spec/update`,
      method: 'post',
      data,
      isLoading: true
    })
  )
}

/**
 * 编辑获取详情
 * @param {*} "specId"
 * @returns
 */
export const specValue = (params) => {
  return awaitWrap(
    request({
      url: `/spec/value`,
      method: 'get',
      params,
      isLoading: false
    })
  )
}
/**
 * 隐藏/显示
 * @param {*} "specId"
 * @returns
 */
export const specStatus = (params) => {
  return awaitWrap(
    request({
      url: `/spec/status`,
      method: 'get',
      params,
      isLoading: false
    })
  )
}
